import { followType, postType } from "@/config/postsType";
import { mapGetters, mapMutations } from "vuex";

import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import { apiUrl } from "@/config/apiUrl";

export default {
  mixins: [validationError, waitRequest],
  computed: {
    ...mapGetters({
      user: "user",
      flows: "flows/flows",
      flowsMeta: "flows/flowsMeta",
      storageSlide: "flows/storageSlide",
    }),

    page() {
      return this.flowsMeta?.current_page || 1;
    },
  },

  data() {
    return {
      flowType: followType.recommend,
    };
  },

  methods: {
    ...mapMutations({
      setPosts: "flows/setPosts",
      updatePosts: "flows/updatePosts",
      setMeta: "flows/setMeta",
      setActiveVideoIndex: "flows/setActiveVideoIndex",

      setStorageSlide: "flows/setStorageSlide",
    }),
    initialLoadFlow() {
      this.setStorageSliderIndex(0);
      this.flowType = this.getStorageFlowType();

      this.loadPosts(this.setCurrentPosts).then(() => {
        this.preloadVideo();
      });
    },

    loadAnonymousFlow(posts) {
      this.setStorageSliderIndex(0);
      this.flowType = this.getStorageFlowType();

      this.setPosts({ posts, type: postType.flows });

      const mockMeta = {
        current_page: 1,

        first_page_url: `${apiUrl}/posts?page=1`,
        from: 1,
        last_page: 2,
        last_page_url: `${apiUrl}/posts?page=2`,
        links: [{ url: null, label: "&laquo; Previous", active: false }],
        next_page_url: `${apiUrl}/posts?page=2`,
        path: `${apiUrl}/posts`,
        per_page: 20,
        prev_page_url: null,
        to: 20,
        total: 28,
      };

      this.setMeta({ meta: mockMeta, type: postType.flows });
      this.initialPage = 1;
    },

    preloadVideo() {
      const videoObjectList = this.flows.map((post) => post?.media?.at(0));

      videoObjectList.forEach(this.createPreloadVideo);
    },

    createPreloadVideo(videoObject) {
      const video = document.createElement("video");

      if (!videoObject?.url) return;

      const { id: videoId = "", jwt = "" } = videoObject.url;

      video.src = `${videoId}?token=${jwt}`;
      video.preload = "auto";
      video.load();
    },

    async initialLoadPost(postId) {
      this.$showSpinner();

      if (postId) {
        this.requestInProgress = false; // do not remove
        this.setStorageSliderIndex(0);
        this.flowType = this.getStorageFlowType();
        this.setActiveVideoIndex(parseInt(postId));

        await this.loadCurrentPost(postId);
        this.loadPosts(this.updateCurrentPosts);

        return;
      }

      this.loadPosts(this.setCurrentPosts);
    },

    loadPosts(successCallback) {
      if (this.requestInProgress) return;
      this.$showSpinner();

      const url = new URL("/posts", process.env.VUE_APP_API_URL);
      url.searchParams.set("flow_type", this.flowType);
      url.searchParams.set("type", postType.flows);

      const requestUrl = url.pathname + url.search;

      return this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    loadPostError(errors) {
      console.log(errors);
    },

    loadMore() {
      return this.loadPosts(this.updateCurrentPosts);
    },

    setCurrentPosts(data) {
      let { current_page, data: posts } = data;

      const isFollowFlowEmpty = !posts.length && this.flowType !== followType.recommend
      if (isFollowFlowEmpty) {
        this.flowType = followType.recommend;

        if(this.$route.name === 'flow') {
          this.$popup.open("ConfirmPopup", {
            button: "Ok",
            title: this.$t("Info"),
            text: "While you have not subscribed to anyone yet, only recommended flows will be available",
          });
        }

        return;
      }
      if (!posts.length) {
        this.requestInProgress = false;
        return this.initialLoadPost(this.postId);
      }

      const firstPostId = posts[0]?.id;
      this.setActiveVideoIndex(firstPostId);

      this.setPosts({ posts, type: postType.flows });
      this.setMeta({ meta: data, type: postType.flows });

      this.initialPage = current_page;
    },

    updateCurrentPosts(data) {
      let posts = data.data;

      if (posts.length < 2) {
        this.requestInProgress = false;
        return this.loadMore();
      }

      this.updatePosts({ posts, type: postType.flows });
      this.setMeta({ meta: data, type: postType.flows });
    },

    async loadCurrentPost(postId) {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.$get(
          `/posts/${postId}`,
          this.setCurrentPost,
          this.loadPostError,
          this.checkErrors
        );
      });
    },

    setCurrentPost(data) {
      this.setPosts({ posts: [data], type: postType.flows });
    },

    setStorageFlowType(flowType) {
      localStorage.setItem(`${this.user?.id}:flow:flowType`, flowType);
    },

    getStorageFlowType() {
      try {
        return (
          localStorage.getItem(`${this.user?.id}:flow:flowType`) ||
          followType.recommend
        );
      } catch (error) {
        return followType.recommend;
      }
    },

    setStorageSliderIndex(index) {
      this.setStorageSlide(index);
      // localStorage.setItem(
      //   `${this.user?.id}:flow:activeSliderVideoIndex`,
      //   index
      // );
    },

    getStorageSliderIndex() {
      try {
        return this.storageSlide;
        // return (
        //   localStorage.getItem(
        //     `${this.user?.id}:flow:activeSliderVideoIndex`
        //   ) || 0
        // );
      } catch (error) {
        return 0;
      }
    },

    removeStorageSliderIndex() {
      this.setStorageSlide(0);
      // localStorage.removeItem(`${this.user?.id}:flow:activeSliderVideoIndex`);
    },
  },
};
