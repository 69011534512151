<template>
  <div>
    <b-container class="tippy">
      <b-row>
        <!--       side-->
        <b-col xl="3" lg="4" md="3" sm="3" class="side-bar px-0">
          <b-row class="gy-2">
            <b-col cols="12" md="12" lg="12" xl="12">
              <b-row class="avatar-row">
                <b-col xl="3" lg="3" md="12">
                  <b-avatar
                    :text="(currentUser.initials && currentUser.initials) || ''"
                    :src="currentUser.avatar"
                    :to="currentUser.url"
                    class="bg-secondary text-white test d-block m-auto"
                    size="3.4375rem"
                  />
                </b-col>

                <b-col xl="9" lg="9" class="avatar-row__name">
                  <ui-username :user="currentUser" />

                  <div class="small text-secondary username mt-1 hide-sm">
                    @{{ currentUser.username }}
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              md="6"
              lg="8"
              xl="12"
              class="overflow-hidden mt-2 hide-md"
            >
              <div class="mt-3 mb-1 mb-xl-2 hide-lg avatar-row__statistics">
                <span class="info">
                  <CLink to="/fans" class="d-inline info__link">
                    {{ userStat.fans }} {{ $t("general.stat.fans") }}
                  </CLink>
                  ·
                  <CLink to="/followers" class="d-inline info__link">
                    {{ userStat.followers }} {{ $t("general.stat.followers") }}
                  </CLink>
                  ·
                  <CLink to="/following" class="d-inline info__link">
                    {{ userStat.following }}
                    {{ $t("general.stat.following") }}
                  </CLink>
                </span>
              </div>
            </b-col>
          </b-row>

          <div class="border-top nav-list">
            <div
              v-for="item in menuDesktop"
              :key="item.id"
              class="nav-list__item"
            >
              <CLink
                :to="item.link"
                class="nav-list__link"
                exact-active-class="nav-list__link--active"
              >
                <CIcon :name="item.icon" class="nav-list__icon" />

                <span class="nav-list__font nav-list__font--text">
                  {{ item.text }}
                </span>
              </CLink>
            </div>
          </div>

          <div class="border-top nav-list">
            <div v-for="item in menuBase" :key="item.id" class="nav-list__item">
              <CLink
                :to="item.link"
                class="nav-list__link"
                active-class="nav-list__link--active"
              >
                <CIcon :name="item.icon" class="nav-list__icon" />

                <span class="nav-list__font nav-list__font--text">
                  {{ item.text }}
                </span>
              </CLink>
            </div>
          </div>

          <div class="border-top nav-list">
            <div v-for="item in menuInfo" :key="item.id" class="nav-list__item">
              <CLink
                :to="item.link"
                class="nav-list__link"
                :class="{ 'nav-list__link--disabled': item.disabled }"
              >
                <CIcon :name="item.icon" class="nav-list__icon" />

                <span class="nav-list__font nav-list__font--text">
                  {{ item.text }}
                </span>

                <span class="nav-list__font nav-list__font--addition">
                  {{ item.addition ? "·" : "" }} {{ item.addition }}
                </span>
              </CLink>
            </div>
          </div>

          <div class="border-top nav-list" v-if="menuSettings.length">
            <div
              v-for="item in menuSettings"
              :key="item.id"
              class="nav-list__item"
            >
              <button
                type="button"
                @click.prevent.stop="item.action"
                class="nav-list__link nav-list__link--button"
              >
                <CIcon :name="item.icon" class="nav-list__icon" />

                <span class="nav-list__font nav-list__font--text">
                  {{ item.text }}
                </span>
              </button>
            </div>
          </div>

          <div class="border-top nav-list">
            <div class="nav-list__item">
              <button
                type="button"
                @click.prevent="logOut"
                class="nav-list__link nav-list__link--button"
              >
                <CIcon name="menu/logout" class="nav-list__icon" />

                <span class="nav-list__font nav-list__font--text">
                  {{
                    isAnonymousUser
                      ? $t("general.menu.list.login")
                      : $t("general.menu.list.logout")
                  }}
                </span>
              </button>
            </div>

            <div class="nav-list__item nav-list__item--icon">
              <button
                type="button"
                class="nav-list__icon-wrapper"
                @click.prevent="$router.push({ name: 'posts-create' })"
              >
                <CIcon
                  name="post"
                  class="nav-list__icon nav-list__icon--create-post"
                />
              </button>
            </div>

            <div class="nav-list__item nav-list__item--create-post">
              <CButton
                type="button"
                @click.prevent="$router.push({ name: 'posts-create' })"
              >
                <span class="nav-list__font nav-list__font--create-post">
                  Create Post
                </span>
              </CButton>
            </div>
          </div>
        </b-col>

        <b-col
          xl="6"
          lg="5"
          md="6"
          sm="9"
          cols="12"
          class="border-left border-right content"
        >
          <router-view :key="$route.name + JSON.stringify($route.params)" />

          <div class="spinner" v-if="spinner">
            <b-spinner variant="secondary" />
          </div>
        </b-col>

        <b-col
          v-if="$route.name !== 'collections'"
          xl="3"
          lg="3"
          md="3"
          sm="3"
          class="d-none d-lg-block"
        >
          <PageCollections v-if="!isAnonymousUser" />
        </b-col>
      </b-row>
    </b-container>

    <FooterMenu />
    <SideMenu />
  </div>
</template>

<script>
import UiUsername from "@/components/ui/UiUsername.vue";
import SideMenu from "@/layout/SideMenu.vue";
import FooterMenu from "@/layout/FooterMenu.vue";
import CIcon from "@/features/ui/CIcon.vue";
import CLink from "@/features/ui/CLink.vue";
import CButton from "@/features/ui/СButton.vue";

import { mapGetters } from "vuex";
import PageCollections from "@/pages/PageCollections.vue";
import flow from "@/mixins/flow";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import sideMenu from "@/mixins/sideMenu";
export default {
  components: {
    PageCollections,
    CButton,
    UiUsername,
    CLink,
    CIcon,
    FooterMenu,
    SideMenu,
  },
  mixins: [flow, waitRequest, validationError, sideMenu],

  data() {
    return {
      fans: 0,
      followers: 0,
      following: 0,
    };
  },

  methods: {
    logOut() {
      this.$post(
        "/auth/logout",
        {},
        () => {},
        (errors) => {
          console.log(errors);
        }
      );
      this.$saveToken(null);
      this.$saveUser(null);
      this.$router.push({ name: "login" });
    },
  },
  computed: {
    ...mapGetters({
      userStats: "user",
      isAnonymousUser: "isAnonymousUser",
    }),

    spinner() {
      return this.$store.state.spinner == true;
    },
    updates() {
      return this.$store.state.updates;
    },

    isAnonymousUser() {
      const user = this.$store.state?.currentUser;
      if (!user) return true;

      return user && user?.is_anonymous;
    },

    userStat() {
      return (
        this.currentUser?.stat || {
          fans: 0,
          followers: 0,
          following: 0,
          views: 0,
          likes: 0,
        }
      );
    },

    currentUser() {
      return this.$store.state.currentUser || {};
    },

    menuDesktop() {
      return [
        {
          id: 1,
          icon: "menu/home",
          link: "/",
          text: this.$t("general.menu.list.home"),
        },
        {
          id: 2,
          icon: "menu/flow",
          link: "/flow",
          text: this.$t("general.menu.list.flow"),
        },
        {
          id: 3,
          icon: "menu/chat",
          link: "/messages",
          text: this.$t("general.menu.list.chat"),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
// tippy fix position
.tippy {
  overflow-x: auto;
  @include hideScroll();
}

@import "~@/assets/scss/vendors/_variables.scss";
@include media-breakpoint-down(md) {
  .nav {
    text-align: center;
  }
}
@include media-breakpoint-up(lg) {
  .nav {
    text-align: left;
  }
}
a.nav-link {
  font-size: 1.2rem;
  line-height: 1.4rem;
  padding: 0.75rem 1rem;
  vertical-align: middle;
}
.icon {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4rem;
  .bi-dot {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 3.5rem;
    line-height: 1;
    margin: -1.8rem;
  }
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.nav-list {
  $parent: &;

  padding-top: em(16);
  padding-bottom: em(20);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;

  &__link {
    color: $black;
    text-decoration: none;

    &--button {
      justify-content: flex-start;

      &:active {
        transform: none;
        filter: none;
      }
    }

    &--disabled {
      pointer-events: none;
    }

    &--active {
      #{$parent} {
        &__icon {
          ::v-deep path {
            stroke-width: 3px;
          }
        }

        &__font {
          &--text {
            color: $black;
            font-weight: 700;
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    position: relative;
    padding: em(3) em(34) em(3) em(55);
    margin-left: em(18);

    &:not(:last-child) {
      margin-bottom: em(24);
    }

    @include media-breakpoint-down(md) {
      padding: em(19);
      margin: auto;
    }

    &--create-post {
      padding: em(0);
      margin-right: em(15);

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &--icon {
      padding: em(10);
      margin: auto;
      background-color: $app-blue;
      border-radius: 50%;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__icon-wrapper {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  &__icon {
    width: em(28, 22);
    height: em(28, 22);

    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    display: inline-block;

    &--create-post {
      width: em(20);
      height: em(20);
      color: $white;
      position: relative;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }

  &__font {
    color: $app-black;
    font-family: $font-default;
    font-size: em(18);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--text {
      color: $app-black;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &--addition {
      color: $app-font-color-gray-4;
      white-space: nowrap;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &--create-post {
      color: $white;
      font-size: em(14, 12);
      font-weight: 700;
    }
  }
}

.info {
  color: $app-black;
  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &__link {
    color: $app-black;

    &:hover {
      color: $app-black;
      text-decoration: none;
    }

    &:active {
      color: $app-black;
    }
  }
}

.side-bar {
  @include media-breakpoint-down(xs) {
    flex-grow: 0 !important;
    display: none;
  }

  @include media-breakpoint-down(md) {
    flex-grow: 0 !important;
  }
}

.hide-sm {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.hide-md {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.hide-lg {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.avatar-row {
  margin-left: em(5);
  margin-top: em(20);

  @include media-breakpoint-down(md) {
    margin-left: -15px;
    margin-bottom: em(10);
  }

  &__name {
    margin-top: em(5);
    margin-left: em(-15);

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__statistics {
    margin-left: em(16);
  }
}
</style>
