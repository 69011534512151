<template>
  <layout-authorized v-if="isLoggedIn || isFlowPage || isUserPage || availablePageList" />
  <layout-auth v-else />
</template>

<script>
import LayoutAuthorized from "./LayoutAuthorized.vue";
import LayoutAuth from "@/layout/user/LayoutAuth.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import flow from "@/mixins/flow";
export default {
  components: { LayoutAuth, LayoutAuthorized },
  mixins: [flow],
  computed: {
    ...mapGetters({
      isAnonymousUser: "isAnonymousUser",
      token: 'token',
    }),
    isLoggedIn() {
      return this.token && !this.isAnonymousUser;
    },
    isUserPage() {
      return this.$route.name === 'user';
    },
    isFlowPage() {
      return ["flow", "flow-post"].includes(this.$route.name);
    },
    availablePageList() {
      return ['login', 'flow', 'signup', 'forgot'].includes(this.$route.name);
    }
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapActions({
      fetchSettings: "settings/fetchSettings",
    }),
    ...mapMutations({
      setPosts: "flows/setPosts",
    }),

    initState() {
      if(!this.availablePageList && !this.isLoggedIn) {
        this.$router.push({ name: "flow" });
      }

      if(!this.isLoggedIn) {
        console.log('this.isAnonymousUser', this.$store.state.currentUser)
        this.preloadAnonymousFlowHandler();
      }

      if (this.isLoggedIn) {
        this.fetchSettings().catch(this.checkErrors);
        this.authHandler();
      } else {
        if (this.$route.name === "login") {
          this.$router.push({ name: "login" });
          return;
        } else if (this.$route.name === "user") {
          console.log("continue");
        }

        if (this.$route.name === "signup") {
          this.$router.push({ name: "signup" });
          return;
        } else if (this.$route.name === "user") {
          console.log("user page");
          // this.$router.push({ name: "signup" });
          return;
        }
      }
    },

    preloadAnonymousFlow(data) {
      this.$saveUser(data.user);
      if(data?.token ) {
        this.$saveToken(data?.token );
      } else {
        this.$saveToken(localStorage.getItem('token'));
      }

      this.loadAnonymousFlow(data.posts);
    },

    preloadAnonymousFlowHandler() {
      if (this.$route.params?.post) {
        this.$get(
          `/flow-lending/${this.$route.params?.post}`,
          this.preloadAnonymousFlow,
          () => {},
          this.checkErrors
        );
      } else {
        this.$get(
          "/flow-lending",
          this.preloadAnonymousFlow,
          () => {},
          this.checkErrors
        );
      }
    },

    authHandler() {
      this.$get(
        "/auth/me",
        (data) => {
          this.$saveUser(data);
          this.initialLoadFlow();
        },
        () => {},
        this.checkErrors
      );
    },
  },
};
</script>
