<template>
  <b-sidebar id="sidebar" no-header backdrop right>
    <b-container fluid class="p-3">
      <b-row class="gy-2">
        <b-col cols="6">
          <b-avatar
            :text="(currentUser.initials && currentUser.initials) || ''"
            :src="currentUser.avatar"
            :to="currentUser.url"
            class="bg-secondary text-white test"
            size="3.4375rem"
          />
        </b-col>

        <b-col cols="12" class="overflow-hidden mt-2">
          <ui-username :user="currentUser" />

          <div class="small text-secondary username mt-1">
            @{{ currentUser.username }}
          </div>

          <div class="mt-3 mb-1">
            <span class="info">
               <CLink to="/fans" class="d-inline info__link">
               {{ userStat.fans }} {{ $t("general.stat.fans") }}
              </CLink> ·
              <CLink to="/followers" class="d-inline info__link">
                 {{ userStat.followers }} {{ $t("general.stat.followers") }}
              </CLink> ·
              <CLink to="/following" class="d-inline info__link">
                {{ userStat.following }}
                {{ $t("general.stat.following") }}
              </CLink>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div class="border-top nav-list">
      <div v-for="item in menuBase" :key="item.id" class="nav-list__item">
        <CLink :to="item.link" class="nav-list__link">
          <CIcon :name="item.icon" class="nav-list__icon" />

          <span class="nav-list__font nav-list__font--text">
            {{ item.text }}
          </span>
        </CLink>
      </div>
    </div>

    <div class="border-top nav-list">
      <div v-for="item in menuInfo" :key="item.id" class="nav-list__item">
        <CLink
          :to="item.link"
          class="nav-list__link"
          :class="{ 'nav-list__link--disabled': item.disabled }"
        >
          <CIcon :name="item.icon" class="nav-list__icon" />

          <span class="nav-list__font nav-list__font--text">
            {{ item.text }} {{ item.addition ? "·" : "" }}
          </span>

          <span class="nav-list__font nav-list__font--addition">
            {{ item.addition }}
          </span>
        </CLink>
      </div>
    </div>

    <div class="border-top nav-list" v-if="menuSettings.length">
      <div v-for="item in menuSettings" :key="item.id" class="nav-list__item">
        <button
          type="button"
          @click.prevent.stop="item.action"
          class="nav-list__link nav-list__link--button"
        >
          <CIcon :name="item.icon" class="nav-list__icon" />

          <span class="nav-list__font nav-list__font--text">
            {{ item.text }}
          </span>
        </button>
      </div>
    </div>

    <div class="border-top nav-list">
      <div class="nav-list__item">
        <button
          type="button"
          @click.prevent="logOut"
          class="nav-list__link nav-list__link--button"
        >
          <CIcon name="menu/logout" class="nav-list__icon" />

          <span class="nav-list__font nav-list__font--text">
            {{
              isAnonymousUser
                ? $t("general.menu.list.login")
                : $t("general.menu.list.logout")
            }}
          </span>
        </button>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import UiUsername from "@/components/ui/UiUsername.vue";

import CLink from "@/features/ui/CLink.vue";
import CIcon from "@/features/ui/CIcon.vue";
import { mapActions, mapGetters } from "vuex";
import sideMenu from "@/mixins/sideMenu";
export default {
  name: "SideMenu",
  components: { CIcon, CLink, UiUsername },
  mixins: [sideMenu],
  computed: {
    ...mapGetters({
      userStats: "user",
      isAnonymousUser: "isAnonymousUser",
    }),

    userStat() {
      return (
        this.currentUser?.stat || {
          fans: 0,
          followers: 0,
          following: 0,
          views: 0,
          likes: 0,
        }
      );
    },

    currentUser() {
      return this.$store.state?.currentUser || {};
    },
  },

  data() {
    return {
      fans: 19,
      followers: 918,
      following: 92,
    };
  },

  mounted() {
    this.initState();
  },

  methods: {
    ...mapActions({
      fetchWallet: "wallet/fetchWallet",
    }),
    initState() {
      if (!this.isAnonymousUser) {
        this.fetchWallet().catch(this.checkErrors);
      }
    },

    logOut() {
      this.$post(
        "/auth/logout",
        {},
        () => {},
        (errors) => {
          console.log(errors);
        }
      );

      if(!this.isAnonymousUser) {
        this.$saveToken(null);
        this.$saveUser(null);
      }

      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
.nav-list {
  padding-top: em(16);
  padding-bottom: em(20);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;

  &__link {
    color: $black;
    text-decoration: none;

    &--button {
      justify-content: flex-start;

      &:active {
        transform: none;
        filter: none;
      }
    }

    &--disabled {
      pointer-events: none;
    }
  }

  &__item {
    display: flex;
    position: relative;
    padding: em(3) em(34) em(3) em(29);
    margin-left: em(18);

    &:not(:last-child) {
      margin-bottom: em(20);
    }
  }

  &__icon {
    width: em(24);
    height: em(24);

    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    display: inline-block;
  }

  &__font {
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &--text {
      color: $app-black;
    }

    &--addition {
      color: $app-font-color-gray-4;
    }
  }
}

.info {
  color: $app-black;
  font-family: $font-default;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &__link {
    color: $app-black;

    &:hover {
      color: $app-black;
      text-decoration: none;
    }

    &:active {
      color: $app-black;
    }
  }
}
</style>
