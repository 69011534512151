<template>
  <CLink v-if="asLink" :to="user.url" class="d-flex align-items-center">
    <span :class="bold ? 'font-weight-bold' : ''" class="username">
      {{ user.name }}
    </span>
    <!--    <i class="bi-patch-check ml-1" v-if="user.isCreator"></i>-->
    <span class="text-muted username ml-1" v-if="full">
      {{ "@" + user.username }}
    </span>
  </CLink>

  <div v-else class="d-flex align-items-center">
    <span :class="bold ? 'font-weight-bold' : ''" class="username">
      {{ user.name }}
    </span>
    <!--    <i class="bi-patch-check ml-1" v-if="user.isCreator"></i>-->
    <span class="text-muted username ml-1" v-if="full">
      {{ "@" + user.username }}
    </span>
  </div>
</template>

<script>
import User from "../models/User";
import CLink from "@/features/ui/CLink.vue";
export default {
  components: {CLink},
  props: {
    user: User,
    full: {
      type: Boolean,
      default: false,
    },
    asLink: {
      type: Boolean,
      default: true,
    },
    bold: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.username {
  color: $app-black;
}
</style>
