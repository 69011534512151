<template>
  <b-nav
    class="position-fixed footer w-100 justify-content-between align-items-center d-flex footer-menu"
    :class="[{ 'd-sm-none': !isFlowMenu, 'footer-menu--black': isFlowMenu }]"
  >
    <CLink to="/" class="footer-menu__link" exact-active-class="footer-menu__link--active">
      <div class="icon d-flex flex-column align-items-center">
        <CIcon name="home" path="footer-menu" class="footer-menu__icon" />
        <span class="footer-menu__text">
          {{ $t("general.footer-menu.home") }}
        </span>
      </div>
    </CLink>

    <CLink to="/flow" class="footer-menu__link" exact-active-class="footer-menu__link--active">
      <div class="icon d-flex flex-column align-items-center">
        <CIcon
          :name="isFlowMenu ? 'flow-filled' : 'flow'"
          path="footer-menu"
          class="footer-menu__icon"
        />
        <span class="footer-menu__text">{{
          $t("general.footer-menu.flows")
        }}</span>
      </div>
    </CLink>

    <CLink to="/posts/create" class="footer-menu__link" exact-active-class="footer-menu__link--active">
      <div class="icon d-flex flex-column align-items-center">
        <CIcon name="post" path="footer-menu" class="footer-menu__icon" />
        <span class="footer-menu__text">{{
          $t("general.footer-menu.post")
        }}</span>
      </div>
    </CLink>

    <CLink to="/messages" class="footer-menu__link" exact-active-class="footer-menu__link--active">
      <div class="icon d-flex flex-column align-items-center">
        <CIcon name="chat" path="footer-menu" class="footer-menu__icon" />
        <span class="footer-menu__text">{{
          $t("general.footer-menu.chat")
        }}</span>
      </div>
    </CLink>

    <CLink v-b-toggle.sidebar class="footer-menu__link" exact-active-class="footer-menu__link--active">
      <div class="icon d-flex flex-column align-items-center">
        <CIcon name="menu" path="footer-menu" class="footer-menu__icon" />
        <span class="footer-menu__text">
          {{ $t("general.footer-menu.menu") }}
        </span>
      </div>
    </CLink>
  </b-nav>
</template>

<script>
import CIcon from "@/features/ui/CIcon.vue";
import CLink from "@/features/ui/CLink.vue";

export default {
  name: "FooterMenu",
  components: { CLink, CIcon },
  props: {
    isFlowMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    updates() {
      return this.$store.state.updates;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-menu {
  $parent: &;

  min-height: em(64);
  background-color: $white;
  padding: 0 em(23);
  flex-wrap: nowrap;

  &__icon {
    width: em(24);
    height: em(24);
    color: $app-font-color-gray-5;
    padding-bottom: em(2);
  }

  &__link {
    width: auto;
    padding: em(4) em(16) em(6);

    &--active {
      #{$parent}__icon {
        color: $app-font-color-gray-1;
      }
      #{$parent}__text {
        color: $app-font-color-gray-1;
      }
    }
  }

  &__text {
    text-align: center;
    font-family: $font-default;
    font-size: em(10);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $app-font-color-gray-5;
  }

  &--black {
    background-color: $black;
    border-top: 0.5px solid $app-black-2;
  }
}
</style>
