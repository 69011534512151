import { mapGetters } from "vuex";
import { currencyFormat } from "@/tools/helpers";
import User from "@/components/models/User";

export default {
  computed: {
    ...mapGetters({
      wallet: "wallet/wallet",
      user: "user",
    }),
    balance() {
      const balance = parseFloat(this.wallet?.balance || 0);

      return this.currencyFormat(balance);
    },
    isCreatorRole() {
      return this.user?.role?.includes(User.ROLE_CREATOR) && !this.isAnonymousUser;
    },
    menuBase() {
      const currentMenu = [
        {
          id: 1,
          icon: "menu/profile",
          link: this.currentUser.url,
          text: this.$t("general.menu.list.my-profile"),
          show: true,
        },
        {
          id: 2,
          icon: "menu/bookmark",
          link: "/collections",
          text: this.$t("general.menu.list.collections"),
          show: true,
        },
        {
          id: 7,
          icon: "menu/referrals",
          link: "/referrals",
          text: this.$t("general.menu.list.referrals"),
          show: true,
        },
        {
          id: 8,
          icon: "menu/queue",
          link: "/queue",
          text: this.$t("general.menu.list.queue"),
          show: this.isCreatorRole,
        },
        {
          id: 9,
          icon: "menu/vault",
          link: "/vault",
          text: this.$t("general.menu.list.vault"),
          show: this.isCreatorRole,
        },

        // hide on pre-mvp
        // {
        //   id: 3,
        //   icon: "menu/repeat",
        //   link: "/subscriptions",
        //   text: this.$t("general.menu.list.my-subscriptions"),
        // },

        {
          id: 6,
          icon: "statements/statements",
          link: "/statements",
          text: this.$t("general.menu.list.statements"),
          show: this.isCreatorRole,
        },

        {
          id: 5,
          icon: "subscriptions/promo",
          link: "/promotions",
          text: this.$t("general.menu.list.promo"),
          show: this.isCreatorRole,
        },


        {
          id: 4,
          icon: "menu/settings",
          link: "/settings-menu",
          text: this.$t("general.menu.list.settings"),
          show: true,
        },
      ];

      return currentMenu.filter(item => item.show)
    },
    menuInfo() {
      return [
        // hide on pre-mvp
        {
          id: 5,
          icon: "menu/card",
          link: "/my-cards",
          disabled: false,
          text: this.$t("general.menu.list.my-cards.base"),
          addition: this.$t("general.menu.list.my-cards.addition"),
        },
        {
          id: 6,
          icon: "menu/wallet-money",
          link: "/my-wallets",
          disabled: false,
          text: this.$t("general.menu.list.my-wallet.base"),
          addition: this.balance,
        },
        {
          id: 8,
          icon: "menu/repeat",
          link: "/my-subscription",
          disabled: false,
          text: this.$t("general.menu.list.my-subscriptions"),
          addition: '',
        },
        {
          id: 7,
          icon: "menu/bank",
          link: "/become-creator",
          text: this.$t("general.menu.list.become-a-creator.base"),
          addition: this.$t("general.menu.list.become-a-creator.addition"),
        },
      ];
    },
    menuSettings() {
      return [
        // hide on pre-mvp
        // {
        //   id: 8,
        //   icon: "menu/24",
        //   action: () => console.log("24 support"),
        //   text: this.$t("general.menu.list.help-&-support"),
        // },
        // hide on pre-mvp
        // {
        //   id: 9,
        //   icon: "menu/moon",
        //   action: () => console.log("Dark mode"),
        //   text: this.$t("general.menu.list.dark-mode"),
        // },
        // {
        //   id: 10,
        //   icon: "menu/language",
        //   action: () => this.$popup.open("LanguagePopup"),
        //   text: this.$t("general.menu.list.language"),
        // },
      ];
    },
  },
  methods: {
    currencyFormat,
  },
};
